<template>
	<ion-page>
		<ion-content>
      <div class="blob-container">
        <a-blob color="#FC7B48" position="top"/>
        <a-blob color="#65D7FF" position="bottom"/>
      </div>
			<div class="ion-padding notch-margin-top">
        <ion-row class="a-header">
					<ion-col size="6">
						<div class="a-logo font-monoxil-bold text-bold">
							BEEN<br>THERE<br>TOGETHER
						</div>
					</ion-col>
					<ion-col size="6" style="text-align: right;" class="lang-picker">
						{{ $t("Select language:") }}
						<div class="font-monoxil">
							<span @click="language = lang"
								class="lang-pick" :class="{'font-monoxil-bold': lang === language}" v-for="(lang, key) in languagesAllowed" :key="'lang'+key">{{lang}}</span>
						</div>
					</ion-col>
				</ion-row>
				<div class="select-campaign ion-text-center text-bold">{{ $t("Select deck:") }}</div>
			</div>

			<div class="ion-padding-horizontal">
				<div class="custom-card ion-text-left position-relative" :style="`background-color: ${campaign.color};`"
					v-for="(campaign) in allowedCampaigns" :key="campaign.id" @click="navToCampaign(campaign.hashtag)">
					<h1 class="font-monoxil text-bold">{{campaign.title}}</h1>
					<h5 class="text-medium" v-html="addBEtoSrc(campaign.description)"></h5>
				</div>
			</div>

			<div class="select-campaign ion-text-center ion-padding text-bold">{{ $t("or enter campaign hashtag:") }}</div>

			<div class="ion-padding-horizontal">
				<ion-row class="custom-card">

					<ion-col size="9">
						<ion-input class="custom-input" type="text" v-model="hashtag" />
					</ion-col>
					<ion-col size="3">
						<div :class="{active: isAllowed, nowrap: true}" @click="navToCampaign()">{{ $t("Enter") }}</div>
					</ion-col>
				</ion-row>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import {mapMutations } from "vuex"
import Translations from '@/plugins/w/i18n/translations'
import { toastController } from '@ionic/vue'
import i18n from "@/plugins/w/i18n/index"
import blob from '@/plugins/app/landing/a-blob.vue'

export default {
  components: {
    'a-blob': blob,
  },
	setup() {

	},
	data(){
		return{
			hashtag: '',
			isAllowed: false,
		}
	},
	mounted(){

	},
	methods: {
	  ...mapMutations('offline', ['activeCampaignUpdated']),
		async navToCampaign(hashtag){
	    try {
        const { id } = this.campaigns.filter( c => {
          return c.language === this.language
              && c.hashtag.toLowerCase() === ( hashtag ? hashtag : this.hashtag.toLowerCase())
        })[0]

        this.activeCampaignUpdated(id)
        this.$router.push({name: 'Landing'})

      } catch (e) {
        const toast = await toastController
            .create({
              message: i18n.global.t('Deck not found'),
              duration: 2000
            })
        return toast.present()
      }

		},
		addBEtoSrc(string) {
	    return string.split('src=\"').join('src=\"' + process.env.VUE_APP_BE_URL )
		}
	},
	watch: {
	  language: {
	    handler() {
				Translations.setLocale(this.language)
        this.isAllowed = !!this.campaigns.filter( c => {
          return c.language === this.language && c.hashtag.toLowerCase() === this.hashtag.toLowerCase()
        }).length
			}
		},
		hashtag: {
	    handler() {
				this.isAllowed = !!this.campaigns.filter( c => {
					return c.language === this.language && c.hashtag.toLowerCase() === this.hashtag.toLowerCase()
				}).length
			}
		}
	},
	computed: {
		...mapFields('offline', ['frameEnabled', 'language', 'languagesAllowed', 'campaigns']),
		allowedCampaigns() {
			return this.campaigns.filter( c => {
				return c.language === this.language && c.visible
			})
		}
	},
}

</script>

<style scoped lang="scss">

@media only screen and (min-width:500px) {
  .ion-page {
    max-width: 500px;
    margin: 0 auto;
    border-left: 3px solid black;
    border-right: 3px solid black;
  }


  h1 {
    font-size: 150%;
    margin-top: 0 !important;
    padding: 0;
  }

}


#logo {
  z-index: -1;
  position: fixed;
  display: flex;
  right: 10%;
  left: 10%;
  bottom: 10%;
  top: 10%;

  svg {
    width: auto;
    height: auto;
  }
}

.close-icon {
	width: 10vw;
	height: 10vw;
	margin-top: 5vw;
}

.select-campaign {
	font-size: 100%;
	padding-top: 5vw;
}

h1 {
	font-size: 150%;
	margin-top: 1.5vw;
}

h5 {
	font-size: 90%;
	padding-right: 3vw;
	bottom: 3vw;
}

.lang-pick {
  text-transform: uppercase;
  margin-left: 1vw;
}

.lang-picker {
  font-size: 100%;
  margin-top: 3vw;
}

.blob-container {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index:-1;
}

.custom-input {
  border: 0;
  border-bottom: 1px solid black;
}

.nowrap {
  white-space: nowrap;
  line-height: 40px;
  text-align: center;
  opacity: 0.5;
  &.active {
    opacity: 1
  }
}

.custom-card {
	padding: 2vw;
  padding-bottom: 2.6vw;
  cursor: pointer;
  margin-bottom: 3vw;
	border: 3px solid black;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgb(145, 144, 144);
  background: rgba(255,255,255,0.72);
  .text-medium p{
    margin-bottom: 0;
  }

  h5 {
    padding-right: 0;
    margin-bottom: -2vw;
  }
}

.player {

	svg {
		width: 8vw;
		height: 8vw;
	}

	div {
		font-size: 4.5vw;
		line-height: 7vw;
		margin-right: 1vw;
	}

}

ion-slide{
	margin: 5vw 0 15vw 0;
}

</style>
