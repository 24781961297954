<template>
	<svg viewBox="0 0 200 200" class="position-absolute" :class="position">
		<path d="" :fill="color" :class="'p1'+position ?? ''"></path>
	</svg>
</template>
<script>
import { spline } from '@georgedoescode/spline'
import SimplexNoise from "simplex-noise"

export default {
	name: 'a-blob',
  props: [ 'color', 'position' ],
	mounted(){
		/// our <path> element
		const path = document.querySelector("path.p1" + this.position ?? '')

		// used to set our custom property values
		const root = document.documentElement

		let hueNoiseOffset = 0
		let noiseStep = 0.002

		const simplex = new SimplexNoise()

		const points = createPoints();

		(function animate() {
			path.setAttribute("d", spline(points, 1, true))

			// for every point...
			for (let i = 0; i < points.length; i++) {
				const point = points[i]

				// return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
				const nX = noise(point.noiseOffsetX, point.noiseOffsetX)
				const nY = noise(point.noiseOffsetY, point.noiseOffsetY)
				// map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
				const x = map(nX, -1, 1, point.originX - 5, point.originX + 5)
				const y = map(nY, -1, 1, point.originY - 5, point.originY + 5)

				// update the point's current coordinates
				point.x = x
				point.y = y

				// progress the point's x, y values through "time"
				point.noiseOffsetX += noiseStep
				point.noiseOffsetY += noiseStep
			}

			requestAnimationFrame(animate)
		})()

		function map(n, start1, end1, start2, end2) {
			return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2
		}

		function noise(x, y) {
			return simplex.noise2D(x, y)
		}

		function createPoints() {
			const points = []
			// how many points do we need
			const numPoints = 6
			// used to equally space each point around the circle
			const angleStep = (Math.PI * 2) / numPoints
			// the radius of the circle
			const rad = 75

			for (let i = 1; i <= numPoints; i++) {
				// x & y coordinates of the current point
				const theta = i * angleStep

				const x = 100 + Math.cos(theta) * rad
				const y = 100 + Math.sin(theta) * rad

				// store the point's position
				points.push({
					x: x,
					y: y,
					// we need to keep a reference to the point's original point for when we modulate the values later
					originX: x,
					originY: y,
					// more on this in a moment!
					noiseOffsetX: Math.random() * 1000,
					noiseOffsetY: Math.random() * 1000
				})
			}

			return points
		}

	},
}
</script>

<style scoped lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	height: 100vh;
	display: grid;
	place-items: center;
}

svg {
	width: 130vw;
	height: 130vw;
	z-index: -1;

	left: 20vw;
	top: 10vw;

  path {
    transition: all .5s linear;
  }

  &.bottom {
    width: 110vw;
    height: 110vw;

    top: auto;
    left: -30vw;
    bottom: -30vw;
  }
}

p {
	display: none;
}

@media only screen and (min-width:1024px) {
	svg {
		width: 50vw;
		height: 50vw;
		left: 8vw;
		top: -20vw;

    &.bottom {
      width: 40vw;
      height: 40vw;
      top: auto;
      left: -10vw;
      bottom: -10vw;
    }
	}
}

</style>
